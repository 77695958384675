<template>
  <div>
    <svg :height="`${height}px` || '8px'" :width="`${width}px` || '16px'" :class="`stroke-current text-${color}`" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 5.25V3.375C14 2.87772 13.8025 2.40081 13.4508 2.04917C13.0992 1.69754 12.6223 1.5 12.125 1.5H3.125C2.62772 1.5 2.15081 1.69754 1.79917 2.04917C1.44754 2.40081 1.25 2.87772 1.25 3.375V14.625C1.25 15.1223 1.44754 15.5992 1.79917 15.9508C2.15081 16.3025 2.62772 16.5 3.125 16.5H12.125C12.6223 16.5 13.0992 16.3025 13.4508 15.9508C13.8025 15.5992 14 15.1223 14 14.625V12.75M17 5.25L20.75 9M20.75 9L17 12.75M20.75 9H7.95312"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#EB5757'
    },
    width: {
      type: String,
      default: '22'
    },
    height: {
      type: String,
      default: '18'
    }
  }
}
</script>
<style lang="scss" scoped></style>
